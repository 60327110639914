import React from "react"
import { Result } from "antd"

export default function NotFound(props) {
  return (
    <Result
      status="404"
      title="404"
      subTitle="La page que vous rechercher est introuvable"
    />
  )
}
